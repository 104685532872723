import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch, TextField
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import { validationSchemas } from "../../helpers/validations"

import { QueryOptions } from "../../helpers/query.options"
import { setSnackBar } from '../../models/application/applicationActions'
import { CVExperience } from "../../models/cv/cv";
import { countryService } from "../../models/country/service";
import { Country } from "../../models/country/country";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/ka"
import moment from "moment"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CV } from '../../models/cv/cv'
import { cvService } from '../../models/cv/service'

const MyTextArea = (props) => {
    const { key, label } = props
    const [field, meta] = useField(props as any);
    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', zIndex: 1000, left: -10, top: -4, cursor: 'pointer', backgroundColor: 'white', width: 20, height: 20 }}>
                <HighlightOffIcon onClick={() => {
                    props.onClose()
                }} />
            </div>
            <TextField {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
}



const ExperienceForm = (props) => {
    moment.locale("ka")
    const [locale, setLocale] = useState("ka")
    const { onClose, item } = props
    const [countries, setCountries] = useState<Array<Country>>([])
    const [mode, setMode] = useState('edit')
    const [localValues, setLocalValues] = useState(null as any)
    const [experience, setExperience] = useState<CVExperience>()
    const [responsibilities, setResponsibilities] = useState([{ text: "", id: 0, uid: '' }])

    let error: string = null

    const getValues = (values) => {
        const exp = new CVExperience()
        exp.startDate = `${values.startDate.getFullYear()}-${String(Number(values.startDate.getMonth() + 1))}`
        exp.endDate = `${values.endDate.getFullYear()}-${String(Number(values.endDate.getMonth() + 1))}`

        let responsibilitiesArr = []
        for (var x = 0; x < 6; x++) {
            if (values["note_" + x]) {
                responsibilitiesArr.push(values["note_" + x])
            }
        }
        if (!responsibilitiesArr.length && responsibilities.length) {
            for (var x = 0; x < 6; x++) {
                if (responsibilities[x]) {
                    responsibilitiesArr.push(responsibilities[x].text)
                }
            }
        }
        exp.responsibilities = responsibilitiesArr
        exp.country = { uid: values.country, id: values.country, name: '' }
        exp.title = values.title
        exp.company = values.company
        exp.uid = item ? item.id : ''
        exp.id = item ? item.id : ''
        exp.isCurrent = values.isCurrent
        const cv = new CV()
        cv.experiences = [exp]

        if (mode === 'create') {
            cvService.create(cv).then(resp => {
                if (resp.id) {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'გამოცდილება წარმატებით დაემატა.', open: true })
                    setTimeout(() => {
                        props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        } else {
            cvService.updateNoForm(cv).then(resp => {
                console.log(resp)
                if (resp.id) {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'გამოცდილება წარმატებით განახლდა.', open: true })
                    setTimeout(() => {
                        props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        }
    }

    useEffect(() => {
        setLocalValues({ name: '' })
        if (item) {
            setExperience(item)
            setResponsibilities(item.responsibilities)
        } else {
            setMode("create")
        }
    }, [item])

    useEffect(() => {
        const options = new QueryOptions()
        options.pageSize = 140
        countryService.listWithCounter(options).then(e => {
            setCountries(e.current)
        })

    }, [])

    const formik = <Formik validationSchema={validationSchemas['experience']}
        initialValues={item ? {
            ...item,
            country: item.country.id,
            startDate: new Date(`${item.startDate}-17T03:24:00`),
            endDate: new Date(`${item.endDate}-17T03:24:00`),
        } : { isCurrent: false, startDate: new Date(`${new Date().getUTCFullYear() - 1}-12-17T03:24:00`), endDate: new Date() }}
        onSubmit={(evt) => getValues(evt)}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors, touched, submitCount }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors, values)
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="დაკავებული პოზიცია"
                            variant="outlined"
                            type="text"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.title} />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label="კომპანია"
                            variant="outlined"
                            type="text"
                            name="company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.company} />
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="title" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="company" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={4} style={{}}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <InputLabel id="select-country">ქვეყანა</InputLabel>
                            <Select
                                name="country"
                                labelId="country-label"
                                id="country"
                                value={item ? item.country.id : values.country}
                                onChange={(event, value) => {
                                    const [selected] = countries.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("country", selected.id)
                                    }
                                }}
                                label="ქვეყანა">
                                {countries.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                            <DatePicker
                                maxDate={new Date()}
                                minDate={new Date('1950-12-17T03:24:00')}
                                views={['year', "month"]}
                                label="დაწყების თარიღი"
                                value={values.startDate}
                                onChange={(value) => {
                                    setFieldValue("startDate", value.toDate())
                                }}
                                animateYearScrolling
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={2} style={{ padding: 3, paddingLeft: 20 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                value={values.isCurrent}
                                checked={values.isCurrent}
                                onChange={(e, value) => {
                                    e.persist()
                                    setFieldValue("isCurrent", value)
                                }}
                            />} label="მიმდინარეა " />

                        </FormGroup>
                    </Grid>

                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        {!values.isCurrent ?
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                                <DatePicker
                                    maxDate={new Date()}
                                    minDate={new Date('1950-12-17T03:24:00')}
                                    views={["month", 'year']}
                                    label="დასრულების წელი"
                                    value={values.endDate}
                                    onChange={(value) => {
                                        setFieldValue("endDate", value.toDate())
                                    }}
                                    animateYearScrolling
                                />
                            </MuiPickersUtilsProvider> : <></>}
                    </Grid>

                    <Grid container lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <AddCircleIcon onClick={() => {
                            if (responsibilities.length < 4) {
                                setResponsibilities([...responsibilities, { text: '', id: responsibilities.length + 1, uid: String(new Date().getTime()) }])
                            }
                        }} />
                        <span style={{ marginLeft: 10 }}>
                            დავამატოთ ახალი ფუნქცია-მოვალეობა
                        </span>
                    </Grid>
                    <Grid container lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        {responsibilities.sort((a, b) => a.uid > b.uid ? 1 : -1).map((note, index) => {
                            return <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                                <MyTextArea
                                    key={index}
                                    onClose={() => {
                                        if (note.uid.length) {
                                            setResponsibilities(responsibilities.filter(r => r.uid !== note.uid))
                                            setFieldValue(`note_${index}`, null)
                                        } else {
                                            setResponsibilities(responsibilities.filter(r => r.id !== note.id))
                                        }
                                    }}
                                    onChange={(value) => {
                                        setResponsibilities([...responsibilities.filter(r => r.uid !== note.uid), { ...note, text: value.target.value }])
                                    }}
                                    name={`note_${index}`}
                                    value={note.text}
                                    style={{ width: '100%', marginTop: 4 }}
                                    id="outlined-multiline-static"
                                    label="ფუნქცია- მოვალეობა"
                                    placeholder="ფუნქცია- მოვალეობა"
                                    rows={3}
                                    variant="outlined" />
                            </Grid>
                        })}
                    </Grid>

                    {error && <Grid container lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {errors[error]}
                        </span>
                    </Grid>}
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button

                                color="primary"
                                variant="contained"
                                style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">დადასტურება</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>


        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(ExperienceForm)