import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Checkbox,
    FormGroup,
    FormControlLabel
} from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik"
import { connect } from 'react-redux'
import 'date-fns';
import MomentUtils from '@date-io/moment'

import "moment/locale/ka"
import moment from "moment"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { setSnackBar } from '../../models/application/applicationActions'
import { NameIdItem } from "../../models/common/common";
import { QueryOptions } from "../../helpers/query.options";
import { vacationRequestService, vacationRequestTypeService } from "../../models/request/service";
import { VacationRequest } from "../../models/request/request";
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import { weekDayService } from "../../models/branch/service";
import { WorkingDayHours } from "../../models/WorkingDayHour/wokringDay";

const VacationRequestForm = (props) => {
    moment.locale("ka")
    const { onClose, item, setSnackBar, auth } = props
    const [localValues, setLocalValues] = useState(null as any)
    const [alreadyExistsError, setAlreadyExistsError] = useState("")
    const [types, setTypes] = useState<NameIdItem[]>()
    const [checkedDays, setCheckedDays] = useState<NameIdItem[]>([])
    const [selectedUsedHolidays, setSelectedUsedHolidays] = useState<WorkingDayHours[]>([])
    const [selectedVacation, setSelectedVacation] = useState("")
    const [usedHolidays, setUsedHolidays] = useState<WorkingDayHours[]>([])
    const [requestedDays, setRequestedDays] = useState([])
    const [days, setDays] = useState<NameIdItem[]>()
    const [limitError, setLimitError] = useState<any>({ error: false, workingDays: 0, daysLeft: 0, validated: false })
    const [recoveryError, setRecoveryError] = useState<any>({ error: false, requestedDays: 0, selectedDays: 0, validated: false })
    const [confirmation, setConfirmation] = React.useState({ text: '', open: false, status: {}, callback: null, item: null })
    let error: string = null

    const getValues = (values) => {
        if (limitError.validated) {
            if (selectedVacation === 'უქმე დღის ანაზღაურება') {

                setConfirmation({
                    text: `${limitError.daysLeft} დარჩენილი დღიდან, თქვენი მოთხოვნა გაიგზავნება ${selectedUsedHolidays.length} დღეზე. \n
                მოთხოვნილია ნამუშევარი დასვენების დღის ანაზღაურება: \n ${selectedUsedHolidays?.map(r => moment(r.startTime, 'DD.MM.YYYY hh:mm').format('DD-MMM-YYYY'))} შემდეგ თარიღებზე: ${requestedDays?.map(r => moment(r).format('DD-MMM-YYYY')).toString()}`, open: true, status: item, callback: null, item: values
                })

            } else {
                setConfirmation({
                    text: `${limitError.daysLeft} დარჩენილი დღიდან, თქვენი მოთხოვნა გაიგზავნება  ${limitError.workingDays} დღეზე. \n
                მოთხოვნილი დღეებია: ${requestedDays?.map(r => moment(r).format('DD-MMM-YYYY')).toString()}
                `, open: true, status: item, callback: null, item: values
                })
            }
        }
    }

    const loadDays = () => {
        const options = new QueryOptions()
        options.order = "index"
        weekDayService.list(options).then(r => {
            setDays(r.results)
        })
    }

    useEffect(() => {
        if (selectedUsedHolidays.length) {
            if (requestedDays.length === selectedUsedHolidays.length) {
                recoveryError.error = false
            } else {
                recoveryError.error = true
            }
            if (recoveryError.error == false) {
                recoveryError.validated = true
            }
        } else {
            recoveryError.error = false
            recoveryError.validated = false
        }

    }, [selectedUsedHolidays, recoveryError.requestedDays, requestedDays])

    const submitValidation = (fromDate: Date, toDate: Date, type) => {
        setAlreadyExistsError("")
        if (fromDate && toDate) {
            const request = new VacationRequest()
            request.validation = true
            request.fromDate = fromDate.getTime()
            request.toDate = toDate.getTime()
            request.employee = item.employee.id
            request.type = type

            if (checkedDays.length) {
                request.chosenDays = checkedDays.map(r => r.id).toString()
            }

            vacationRequestService.create(request).then((r: any) => {
                if ([408, 409].indexOf(r.status) > -1) {
                    setAlreadyExistsError(r.response?.message)
                } else {
                    const { daysLeft, workingDays, requestedDays, usedHolidays } = r
                    setUsedHolidays(usedHolidays)
                    setRequestedDays(requestedDays?.map(rd => moment(rd, 'YYYY-MM-DD')))
                    setLimitError({ ...limitError, error: false, workingDays, daysLeft, validated: true })
                    if (selectedVacation === 'უქმე დღის ანაზღაურება') {
                        setRecoveryError({ ...recoveryError, requestedDays: requestedDays.length })
                    }
                }
            })
        }
    }
    const submitRequest = (values) => {
        setAlreadyExistsError("")
        const request = new VacationRequest()
        request.validation = false
        request.fromDate = values.fromDate.getTime()
        request.toDate = values.toDate.getTime()
        request.employee = item.employee.id
        request.type = values.type
        if (selectedUsedHolidays?.length) {
            request.usedHolidays = selectedUsedHolidays.map(d => d.id)
        }
        if (checkedDays.length) {
            request.chosenDays = checkedDays.map(r => r.id).toString()
        }
        vacationRequestService.create(request).then(r => {
            if (r.id) {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'შვებულების ფორმა გაგზავნილია წარმატებით', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
                onClose(false)
            }
        })
    }

    useEffect(() => {
        setLocalValues({ name: '' })
    }, [item])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = (item) => {
        setAnchorEl(null);
    };

    const loadTypes = () => {
        const options = new QueryOptions()
        options.order = "sort"
        options.pageSize = 100
        vacationRequestTypeService.list(options).then(r => {
            setTypes(r.results)
        })
    }
    useEffect(() => {
        loadDays()
        loadTypes()
    }, [])

    const formik = <Formik validationSchema={validationSchemas['vacationRequest']}
        initialValues={{

        }}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors, values)
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{}}>
                    <Grid item lg={12} style={{ paddingTop: 25 }}>

                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{}}>
                    <Grid item lg={selectedVacation.length > 0 ? 4 : 12} style={{ paddingTop: 25 }}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <InputLabel id="select-country">შვებულების სახეობა</InputLabel>
                            <Select
                                name="type"
                                labelId="country-label"
                                id="country"
                                value={values.type}
                                onChange={(event, value) => {
                                    setAlreadyExistsError("")
                                    const [selected] = types?.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("type", selected.id)
                                        setSelectedVacation(selected.name)
                                        submitValidation(values.fromDate, values.toDate, selected.id)
                                    }
                                }}
                                label="შვებულების სახეობა">
                                {types?.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {selectedVacation.length > 0 && <Grid item lg={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    minDate={new Date()}
                                    maxDate={new Date(`2025-12-30T03:24:00`)}
                                    id="date-picker-inline"
                                    label="დაწყების თარიღი"
                                    value={values.fromDate}
                                    onChange={(value) => {
                                        if (value) {
                                            setFieldValue("fromDate", value.toDate())
                                            setLimitError({ ...limitError, error: false })
                                            submitValidation(value.toDate(), values.toDate, values.type)
                                        }
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>}
                    {selectedVacation.length > 0 && <Grid item lg={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    format="DD/MM/yyyy"
                                    margin="normal"
                                    minDate={values.fromDate}
                                    maxDate={new Date(`2025-12-30T03:24:00`)}
                                    id="date-picker-inline"
                                    label="დასრულების თარიღი"
                                    value={values.toDate}
                                    onChange={(value) => {
                                        if (value) {
                                            setFieldValue("toDate", value.toDate())
                                            setLimitError({ ...limitError, error: false })
                                            submitValidation(values.fromDate, value.toDate(), values.type)
                                        }
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>}
                    <Grid item lg={4} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="fromDate" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={4} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="toDate" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={4} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="type" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>

                    {limitError.error && <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <span className="error" style={{ marginTop: 10, fontSize: 12 }}>
                            {`მონიშნული დღეების რაოდენობა (${limitError.workingDays}) მეტია დარჩენილ შვებულების დღეების რაოდენობაზე (${limitError.daysLeft})`}
                        </span>

                    </Grid>}
                    {props.auth?.user?.position?.branch?.workingDayGroup === 'shops' && selectedVacation !== 'უქმე დღის ანაზღაურება' && <div >
                        <p>მონიშნე დასვენების დღეები (მაქსიმუმ II დღე)</p>
                        {days?.map((d, index) => {
                            return <FormControlLabel control={
                                <Switch
                                    checked={checkedDays.map(d => d.id).indexOf(d.id) > -1}
                                    onChange={(e, value) => {
                                        e.persist()
                                        if (checkedDays.map(i => i.id).indexOf(d.id) > -1) {
                                            setCheckedDays([...checkedDays.filter(r => r.id !== d.id)])
                                        } else {
                                            setCheckedDays([...checkedDays, d])
                                        }
                                        if (checkedDays.length > 1) {
                                            const [first] = checkedDays
                                            setCheckedDays([...checkedDays.filter(r => r.id !== first.id), d])
                                        }

                                    }}
                                />} label={<b style={{ fontSize: 13 }}>{d.name}</b>} />
                        })}
                    </div>}

                    {usedHolidays?.length > 0 && selectedVacation === 'უქმე დღის ანაზღაურება' && <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <p>აირჩიე რომელი დასვენების დღის ანაზღაურებაც გსურს: </p>
                        {
                            usedHolidays?.map((holiday, index) => {
                                return <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={selectedUsedHolidays.map(d => d.id).indexOf(holiday.id) > -1} onChange={() => {
                                            if (holiday) {
                                                const holidays = selectedUsedHolidays.filter(d => d.id !== holiday.id)
                                                if (selectedUsedHolidays.map(d => d.id).indexOf(holiday.id) > -1) {
                                                    setSelectedUsedHolidays([...holidays])
                                                    submitValidation(values.fromDate, values.toDate, values.type)
                                                } else {
                                                    setSelectedUsedHolidays([...holidays, ...[holiday]])
                                                    submitValidation(values.fromDate, values.toDate, values.type)
                                                }
                                            }

                                        }} name="usedHoliday" />}
                                        label={moment(holiday.startTime, 'DD.MM.YYYY hh:mm').format('DD-MMM-YYYY')}
                                    />
                                </FormGroup>
                            })
                        }


                    </Grid>}

                    {error && <Grid item lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {errors[error]}
                        </span>
                    </Grid>}
                    {alreadyExistsError.length > 0 && <Grid item lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {alreadyExistsError}
                        </span>

                    </Grid>}
                    {recoveryError.error && <Grid item lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            მონიშნული დღეების რაოდენობა არ ემთხვევა მოთხოვნილი დღეების რაოდენობას
                        </span>

                    </Grid>}
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                disabled={selectedVacation === 'უქმე დღის ანაზღაურება' && !recoveryError.validated}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>
        <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={(c) => {
            if (c) {
                submitRequest(confirmation.item)
                setAnchorEl(null);
            }
            setConfirmation({ text: '', open: false, callback: null } as any)
        }} />


        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(VacationRequestForm)



